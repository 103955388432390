$(window).on('load', () => {
  AOS.init({
    offset: jQuery(window).height() * 0.1,
  });
  const header = document.querySelector('header');

  window.addEventListener('scroll', function (e) {
    let scrollPosition = window.scrollY;
    if (+scrollPosition > 1) {
      if (!header.classList.contains('scrolled')) {
        header.classList.add('scrolled');
        header.nextElementSibling.style.paddingTop = header.offsetHeight +'px';
      }
    } else {
      header.classList.remove('scrolled');
      header.nextElementSibling.style.paddingTop = '0';

    }
  });
  $(".hamburger").on("click", function () {
    console.log( 'click' );
    $(this).toggleClass("is-active");
    $("#nav__primary").toggleClass("active");
    if ($("#nav__primary").hasClass("active")) {
      $("main").addClass("nav-is-active");
    } else {
      $("main").removeClass("nav-is-active");
    }
  });

  $("main").on("click", function () {
    $(".hamburger").removeClass("active");
    $("main").removeClass("nav-is-active");

    // $(".header-nav .nav__primary").removeClass("active");
  });
});
